:root {
  --body-background: #fff;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64079;
}

.dark {
  --body-background: #222;
  --body-color: #ddd;
  --heading-color: #ddd;
  --action-color: #d64079;
}

.badge {
  background-color: var(--action-color);
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  margin-left: 2rem;
}

[hidden] {
  display: none !important;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

button {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
}

#dark-mode-switch-container {
  position: absolute;
  top: 4px;
  right: 2rem;
}

#theme-switch {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  .dark & {
    color: #eab308;
  }
}

.richer-text-editor--bubble-menu-highlighter-menu {
  & button {
    font-size: 1.1rem;
    line-height: 1.5;
  }
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 115%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  margin: 1rem 0 2rem;
  /* text-align: center; */
  /* font-weight: 900; */
  font-size: 2.5rem;
  /* color: var(--heading-color); */
  line-height: 1.2;
}

body > header {
  margin: 0;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 1rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

/* Place a main element and an aside element side by side */
main,
aside {
  display: inline-block;
  vertical-align: top;
}

main {
  margin-left: 2rem;
  width: 70%;
  max-width: 65rem;
}

aside {
  width: 30%;
  max-width: 25rem;
  margin-left: 2rem;
  border-left: 2px solid #ddd;

  & ul {
    list-style-type: none;

    & li {
      margin-bottom: 0.4em;
    }
  }
}

.container-with-sidebar {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  /* Handle narrow screens */
  @media (max-width: 500px) {
    flex-direction: column;
  }
}

footer {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1em;
}

pre {
  background: #f8f8f8;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  margin: 1rem 0;
  overflow: auto;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 2rem 0;
}

[aria-hidden="true"] {
  visibility: hidden;
}

.anchor {
  text-decoration: none;
}

h2:hover .anchor,
h3:hover .anchor,
h4:hover .anchor,
h5:hover .anchor,
h6:hover .anchor {
  visibility: visible;
}

/* Provide nice popout effect for richer-text embeds */
.richer-text-embed {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rainbow {
  background-image: linear-gradient(
    90deg,
    #ff9a9e 0%,
    #fad0c4 15%,
    #fad0c4 15%,
    #fbc2eb 30%,
    #a6c1ee 44%,
    #a6c1ee 44%,
    #fbc2eb 58%,
    #ff9a9e 72%,
    #ff9a9e 72%,
    #fad0c4 87%,
    #fad0c4 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input[type="text"],
input[type="email"] {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin: 1rem 0;
  width: 100%;
}


button.submit {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin: 1rem 0;
  width: 25%;
  background-color: #d64079;
  color: white;
  font-weight: bold;
}
