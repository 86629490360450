.richer-text-editor--without-border {
  --rte-menu-bar-border-color: none;
  --rte-button-size: 20px;
}


.richer-text-editor--with-menu-bar-background {
  --rte-menu-bar-border-color: none;

  --rte-menu-bar-background-color: #efefef;
  --rte-menu-bar-button-background-color: #efefef;
  --rte-menu-bar-button-text-color: var(--rte-button-text-color);
  --rte-menu-bar-button-active-text-color: var(--rte-button-active-color);
  --rte-menu-bar-button-disabled-text-color: var(--rte-button-disabled-color);
  --rte-menu-bar-button-active-background-color: #d7dae0;
}
